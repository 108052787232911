<template>
    <v-dialog
      v-model="shower"
      scrollable
      persistent
      max-width="480px"
      v-if="resolutionScreen >= 500"
    >
      <v-card>
        <v-layout class="pt-2 pb-2">
            <v-flex justify-center>
              <!-- <span :style="headerPage">{{
                $t("dialogconfirmdelete.header")
              }}</span> -->
            </v-flex>
            <v-flex xs1 justify-end>
              <v-icon
                :color="$vuetify.theme.dark ? 'white' : color.theme"
                @click="$emit('closedialog')"
                >mdi-close</v-icon
              >
            </v-flex>
          </v-layout>
        <v-card-text class="pa-1 pt-1 pb-1 text-center" style="height: 170px;">
            <v-icon color="error" size="40">mdi-alert-circle</v-icon>
            <h3 class="pl-4 ma-2 pt-4">
                คุณไม่สามารถเข้าใช้งานหน้าเว็บนี้ได้
            </h3>
            <h3 class="pl-4 ma-2 pt-2">
                เนื่องจากไม่พบสิทธิ์ของท่านในองค์กร
            </h3>
        </v-card-text>
        <!-- <v-card-actions class="justify-center">
            <v-btn color="red" outlined @click="$emit('closedialog')">{{ $t("admin.close") }}</v-btn>
          </v-card-actions> -->
      </v-card>
    </v-dialog>
    <!-- หน้าจอมือถือ -->
    <v-dialog
      v-model="shower"
      scrollable
      persistent
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
      v-else
    >
      <v-card>
          <v-layout class="pt-2 pb-4">
            <v-flex justify-center>
              <!-- <span :style="headerPage">{{
                $t("dialogconfirmdelete.header")
              }}</span> -->
            </v-flex>
            <v-flex xs1 justify-end>
              <v-icon
                :color="$vuetify.theme.dark ? 'white' : color.theme"
                @click="$emit('closedialog')"
                >mdi-close</v-icon
              >
            </v-flex>
          </v-layout>
        <v-card-text class="pa-2 px-4 pt-1 pb-1 text-center" :style="titleText">
            <v-icon color="error" size="30">mdi-alert-circle</v-icon>
            <h3 class="pl-4 ma-2 pt-2">
                คุณไม่สามารถเข้าใช้งานหน้าเว็บนี้ได้
            </h3>
            <h3 class="pl-4 ma-2 pt-2">
                เนื่องจากไม่พบสิทธิ์ของท่านในองค์กร
            </h3>
            <br/>
        </v-card-text>
        <!-- <div class="text-center my-4">
          <v-btn
            :style="btnAction"
            class="mr-6"
            height="40px"
            width="40%"
            color="red"
            outlined
            @click="$emit('closedialog')"
            >{{ $t("changeName.close") }}</v-btn
          >
        </div> -->
      </v-card>
    </v-dialog>
  </template>
  <script>
  import { mapState, mapGetters } from "vuex";
  import Swal from "sweetalert2/dist/sweetalert2.js";
  import "sweetalert2/src/sweetalert2.scss";
  export default {
    props: ["show"],
    computed: {
      ...mapState(["username", "authorize", "account_active", "color"]),
      ...mapState({ processloader: "loading" }),
      ...mapGetters([
        "dataUsername",
        "dataAuthorize",
        "dataAccountActive",
        "dataAccesstoken",
        "dataBusinessProfile",
        "dataCitizenProfile",
        "dataDepartmentAccessId",
        "dataAccountId",
      ]),
      shower: {
        get() {
          if (this.show === true) {
          }
          return this.show;
        },
      },
      resolutionScreen() {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return 220;
          case "sm":
            return 400;
          case "md":
            return 500;
          case "lg":
            return 600;
          case "xl":
            return 800;
        }
      },
      // style อักษร header ของ mobile
      headerPage() {
        return (
          "color:" +
          this.color.theme +
          ";" +
          "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
        );
      },
      // style ปุ่ม ของ mobile
      btnAction() {
        return "font-size: 16px; font-weight: lighter;";
      },
      // style อักษรใน dialog ของ mobile
      titleText() {
        return "color:black; font-size: 14px; line-height: 28px;";
      },
      // ความกว้างของ dialog ของ ipad และ mobile
      maxWidthOnMobile() {
        if(this.resolutionScreen >= 400) {
          return 600
        } else {
          return 346
        }
      },
    },
  };
  </script>